import { FormHelperText, Typography } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Save from '@material-ui/icons/Save'
import { Field, Form, Formik } from 'formik'
import { Select, TextField } from 'formik-material-ui'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { getShops, touropShop } from '../../api/backendClient'
import { edit } from '../../api/promoCodesClient'
import { promocode as promoCodeModel } from '../../model/promocode'
import ButtonGeneric from '../ButtonGeneric'
import { GenericComboboxAutocomplete } from '../formikFormComponents/GenericComboBoxAutocomplete'
import { KeyboardDatePicker } from '../formikFormComponents/KeyboardDatePicker'
import { KeyboardDateTimePicker } from '../formikFormComponents/KeyboardDateTimePicker'
import { NumberField } from '../formikFormComponents/NumberField'

const discountTypeAmount = 'Amount'
const discountTypes = ['Percentage', discountTypeAmount]

const paymentMethods = ['casino', 'amex']

const currencies = ['EUR', 'GBP', 'CHF', 'KES', 'USD', 'KWD', 'SAR', 'QAR', 'BHD', 'AED', 'TRY', 'CAD', 'PLN']

const PromoCodeEditForm = (props: {
  promoCode?: promoCodeModel
  onSuccess?: () => void
  onFail?: (error: any) => void
}) => {
  const [isNewPromoCode] = useState(!props.promoCode?.id)
  const [shops, setShops] = useState<touropShop[]>([])
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    getShops().then(setShops)
  }, [])

  interface promoCodeForm {
    id: string
    brandShop: string
    code: string
    label: string
    notesInternal: string
    discount: number | ''
    discountPerAdult: number | ''
    discountPerChild: number | ''
    discountPerInfant: number | ''
    discountType: string
    currency: string
    paymentMethods: string[]
    placeIds: number[]
    bookingStartDate: Date | null
    bookingEndDate: Date | null
    departureStartDate: string | null
    departureEndDate: string | null
    minimumSpend: number | ''
    partnerData: object | {}
  }

  let initialValues: promoCodeForm = {
    id: '',
    brandShop: '',
    code: '',
    label: '',
    notesInternal: '',
    discount: '',
    discountPerAdult: '',
    discountPerChild: '',
    discountPerInfant: '',
    discountType: 'Amount',
    currency: 'EUR',
    paymentMethods: [],
    placeIds: [],
    bookingStartDate: null,
    bookingEndDate: null,
    departureStartDate: null,
    departureEndDate: null,
    minimumSpend: '',
    partnerData: {
      attributeName: '',
      attributeValue: '',
    },
  }
  if (props.promoCode) {
    initialValues = {
      id: props.promoCode.id,
      brandShop: props.promoCode.brandShop,
      code: props.promoCode.code,
      label: props.promoCode.label,
      notesInternal: props.promoCode.notesInternal || '',
      discount: props.promoCode.discount || '',
      discountPerAdult: props.promoCode.discountPerAdult || '',
      discountPerChild: props.promoCode.discountPerChild || '',
      discountPerInfant: props.promoCode.discountPerInfant || '',
      discountType: props.promoCode.discountType,
      currency: props.promoCode.currency,
      paymentMethods: props.promoCode.paymentMethods || [],
      bookingStartDate: props.promoCode.bookingStartDate ? new Date(props.promoCode.bookingStartDate) : null,
      bookingEndDate: props.promoCode.bookingEndDate ? new Date(props.promoCode.bookingEndDate) : null,
      departureStartDate: props.promoCode.departureStartDate || null,
      departureEndDate: props.promoCode.departureEndDate || null,
      minimumSpend: props.promoCode.minimumSpend || '',
      placeIds: props.promoCode.placeIds || [],
      partnerData: props.promoCode.partnerData || {},
    }
  }

  const validationSchema = Yup.object({
    code: Yup.string().required(),
    brandShop: Yup.string().required(),
    label: Yup.string().required('Required'),
    discount: Yup.number()
      .transform(e => e || 0)
      .min(0)
      .when('discountType', { is: 'Percentage', then: Yup.number().lessThan(100) }),
    discountType: Yup.string().required('Required'),
    currency: Yup.string().required('Required'),

    // bookingStartDate: Yup.date().transform(e => {return e === 'Invalid Date' ? undefined : e }),
    // bookingEndDate: Yup.date().transform(e => e || undefined)
    //   .min(Yup.ref('bookingStartDate'), 'Booking end date must be after start date'),
    // departureStartDate: Yup.date().transform(e => e || undefined),
    // departureEndDate: Yup.date().transform(e => e || undefined)
    //   .min(Yup.ref('departureStartDate'), 'Departure end date must be after start date'),

    minimumSpend: Yup.number()
      .transform(e => e || undefined)
      .positive(),
  })

  return (
    <Grid container spacing={2} direction={'column'}>
      <Grid item>
        <h2>{isNewPromoCode ? 'New' : 'Edit'} PromoCode</h2>
      </Grid>
      <Grid item>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (f: promoCodeForm, { setSubmitting }) => {
            setIsSubmitting(true)
            const promoCode: promoCodeModel = {
              id: f.id,
              code: f.code,
              label: f.label,
              brandShop: f.brandShop,
              currency: f.currency,
              discount: f.discount || 0,
              discountPerAdult: f.discountPerAdult || undefined,
              discountPerChild: f.discountPerChild || undefined,
              discountPerInfant: f.discountPerInfant || undefined,
              discountType: f.discountType,
              bookingStartDate: f.bookingStartDate ? f.bookingStartDate.toISOString() : undefined,
              bookingEndDate: f.bookingEndDate ? f.bookingEndDate.toISOString() : undefined,
              paymentMethods: f.paymentMethods,
              departureStartDate: f.departureStartDate || undefined,
              departureEndDate: f.departureEndDate || undefined,
              minimumSpend: f.minimumSpend || undefined,
              notesInternal: f.notesInternal,
              placeIds: f.placeIds,
              partnerData: f.partnerData,
            }
            edit(promoCode)
              .then(() => {
                setSubmitting(false)
                setIsSubmitting(false)
                if (props.onSuccess) props.onSuccess()
              })
              .catch(error => {
                setSubmitting(false)
                setIsSubmitting(false)
                console.error(error)
                if (props.onFail) props.onFail(error)
              })
              .finally(() => {
                setSubmitting(false)
              })
          }}
        >
          {props => (
            <Form>
              <Grid container>
                <Grid item container spacing={2}>
                  <Grid item xs={6}>
                    <Field name="id" label="id" className="fullWidth" disabled component={TextField} />
                  </Grid>
                  <Grid item xs={6} />
                  <Grid item xs={6}>
                    <FormControl className="fullWidth" size="small">
                      <InputLabel id="brandShop-label">shop</InputLabel>
                      <Field labelId="brandShop-label" component={Select} name="brandShop">
                        {shops.map(shop =>
                          shop.locales.map(locale => {
                            let brandShop = shop.brand.$id + ' ' + locale + '-' + shop.sellingCountryCode
                            return (
                              <MenuItem key={brandShop} value={brandShop}>
                                {brandShop}
                              </MenuItem>
                            )
                          })
                        )}
                      </Field>
                      <FormHelperText />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <Field name="code" label="code" className="fullWidth" component={TextField} />
                  </Grid>
                  <Grid item xs={12}>
                    <Field name="label" label="label" className="fullWidth" component={TextField} />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="notesInternal"
                      label="notesInternal"
                      multiline
                      className="fullWidth"
                      component={TextField}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field name="discount" label="discount" className="fullWidth" type="number" component={TextField} />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl className="fullWidth">
                      <InputLabel htmlFor="discountType">discountType</InputLabel>
                      <Field component={Select} name="discountType" inputProps={{ id: 'discountType' }}>
                        {discountTypes.map(discountType => (
                          <MenuItem key={discountType} value={discountType}>
                            {discountType}
                          </MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4} hidden={props.values.discountType !== discountTypeAmount}>
                    <Field
                      name="discountPerAdult"
                      label="discountPerAdult"
                      className="fullWidth"
                      type="number"
                      component={TextField}
                    />
                  </Grid>
                  <Grid item xs={4} hidden={props.values.discountType !== discountTypeAmount}>
                    <Field
                      name="discountPerChild"
                      label="discountPerChild"
                      className="fullWidth"
                      type="number"
                      component={TextField}
                    />
                  </Grid>
                  <Grid item xs={4} hidden={props.values.discountType !== discountTypeAmount}>
                    <Field
                      name="discountPerInfant"
                      label="discountPerInfant"
                      className="fullWidth"
                      type="number"
                      component={TextField}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl className="fullWidth">
                      <InputLabel htmlFor="currency">currency</InputLabel>
                      <Field component={Select} name="currency" inputProps={{ id: 'currency' }}>
                        {currencies.map(_ => (
                          <MenuItem key={_} value={_}>
                            {_}
                          </MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl className="fullWidth">
                      <InputLabel htmlFor="paymentMethods">paymentMethods</InputLabel>
                      <Field component={Select} name="paymentMethods" multiple>
                        {paymentMethods.map(_ => (
                          <MenuItem key={_} value={_}>
                            {_}
                          </MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      label="bookingStartDate"
                      name="bookingStartDate"
                      className="fullWidth"
                      format="dd/MM/yyyy HH:mm:ss"
                      component={KeyboardDateTimePicker}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      label="bookingEndDate"
                      name="bookingEndDate"
                      className="fullWidth"
                      format="dd/MM/yyyy HH:mm:ss"
                      component={KeyboardDateTimePicker}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      label="departureStartDate"
                      name="departureStartDate"
                      className="fullWidth"
                      format="dd/MM/yyyy"
                      component={KeyboardDatePicker}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      label="departureEndDate"
                      name="departureEndDate"
                      className="fullWidth"
                      format="dd/MM/yyyy"
                      component={KeyboardDatePicker}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="placeIds"
                      label="destinations"
                      multiple
                      component={GenericComboboxAutocomplete}
                      dbType="Place"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <NumberField name="minimumSpend" label="minimumSpend" />
                  </Grid>

                  <Grid item xs={6} />

                  <Grid item xs={12}>
                    <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                      <Grid item xs={2}>
                        <Typography variant="subtitle1">Partner Data</Typography>
                      </Grid>

                      <Grid item xs={5}>
                        <Field
                          name="partnerData.attributeName"
                          label="attributeName"
                          className="fullWidth"
                          component={TextField}
                        />
                      </Grid>

                      <Grid item xs={5}>
                        <Field
                          name="partnerData.attributeValue"
                          label="attributeValue"
                          className="fullWidth"
                          component={TextField}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container item xs={6} justify={'flex-end'}>
                    <ButtonGeneric
                      label={isNewPromoCode ? 'Save new promoCode' : 'Save changes'}
                      isDisabled={isSubmitting}
                      startIcon={<Save />}
                      type="submit"
                      color="secondary"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  )
}

export default PromoCodeEditForm
